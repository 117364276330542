@import '../../../scss/color';

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.spinner {
    width: 56px;
    height: 56px;
    display: grid;
    border: 4.5px solid #0000;
    border-radius: 50%;
    border-color: $pink #0000;
    animation: spin 1s infinite linear;
    
    .spinner::before,
    .spinner::after {
      content: "";
      grid-area: 1/1;
      margin: 2.2px;
      border: inherit;
      border-radius: 50%;
    }
  
    .spinner::before {
      border-color: $deep_pink #0000;
      animation: inherit;
      animation-duration: 0.5s;
      animation-direction: reverse;
    }
  
    .spinner::after {
      margin: 8.9px;
    }
  
    @keyframes spin{
      100% {
        transform: rotate(1turn);
      }
    }
  }
  