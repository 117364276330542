$red: red;
$white: white;
$black: black;
$blue: #1665d8;
$pink: #FA55A4;
$green: #008000;
$yellow: #fdd441;
$btn_red: #BE1818;
$pure_grey:#FAFAFA;
$light_pink: rgb(255, 223, 238);
$ox_blood: #AD1F1F;
$deep_pink: #ff007a;
$bby_pink: #eecedd;
$off_white: #eef2f6;
$sea_green: #2e8b57;
$dim_black: #1e202c;
$dark_gray: #3a3b3f;
$royal_blue: #1847ed;
$light_gray: #dedada;
$light_blue: #add8e6;
$primary_green: #65BB64;
$light_green: lightgreen;
$box_shadow_color: #00000040;
$border_grey: rgba(0, 0, 0, 0.5);
$input_text_black: rgba(0, 0, 0, 0.8);
$white_green: rgba(187, 235, 187, 0.9);
$input_bg_grey: rgba(217, 201, 201, 0.2);
$white_90: rgba(252, 252, 252, 0.9);
$grey_60:  rgba(217, 201, 201, 0.6);
$grey_40: rgba(217, 201, 201, 0.4);
$grey: rgba(39, 39, 39, 0.952)
